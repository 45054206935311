import React, { Suspense } from 'react';
import {
  ThemeProvider,
  createTheme,
  StyledEngineProvider,
} from '@mui/material/styles';
import rtlPlugin from 'stylis-plugin-rtl';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import { prefixer } from 'stylis';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { AuthProvider, useAuth } from './contexts/AuthContext';
import ProtectedRoute from './components/ProtectedRoute';
import Navigation from './components/Navigation';

// Lazy load components
const GroupPurchaseForm = React.lazy(() =>
  import('./components/GroupPurchaseForm')
);
const GroupPurchaseThankYou = React.lazy(() =>
  import('./components/GroupPurchaseThankYou')
);
const GroupPurchaseAdmin = React.lazy(() =>
  import('./components/GroupPurchaseAdmin')
);
const LeadCaptureForm = React.lazy(() =>
  import('./components/LeadCaptureForm')
);
const LeadTable = React.lazy(() => import('./components/LeadTable'));
const ThankYouPage = React.lazy(() => import('./components/ThankYouPage'));
const ProductManagement = React.lazy(() =>
  import('./components/ProductManagement')
);
const OrderPlacementWizard = React.lazy(() =>
  import('./components/OrderPlacementWizard')
);
const LoginScreen = React.lazy(() => import('./components/LoginScreen'));
const OrderThankYouPage = React.lazy(() =>
  import('./components/OrderThankYouPage')
);
const GeneratePDF = React.lazy(() => import('./components/GeneratePDF'));
const OrderManagement = React.lazy(() =>
  import('./components/OrderManagement')
);
const DataVisualizations = React.lazy(() =>
  import('./components/DataVisualizations')
);

// New content components
const ResourceManagement = React.lazy(() =>
  import('./components/ResourceManagement')
);
const KnowledgeGallery = React.lazy(() =>
  import('./components/KnowledgeGallery')
);
const PestsGallery = React.lazy(() =>
  import('./components/PestsGallery')
);
const ResourceDetail = React.lazy(() =>
  import('./components/ResourceDetail')
);

// Create rtl cache
const cacheRtl = createCache({
  key: 'muirtl',
  stylisPlugins: [prefixer, rtlPlugin],
});

const theme = createTheme({
  direction: 'rtl',
  typography: {
    fontFamily: 'open sans, sans-serif',
  },
  palette: {
    primary: {
      main: '#abcd37',
      light: '#7FC31',
      dark: '#f7ef1c',
      contrastText: '#fff',
    },
    secondary: {
      main: '#dc004e',
      light: '#e33371',
      dark: '#9a0036',
      contrastText: '#fff',
    },
  },
});

const CatalogRedirect = () => {
  window.location.href =
    'https://firebasestorage.googleapis.com/v0/b/rpc-leads.appspot.com/o/Catalog%20RPC%202025%20M.pdf?alt=media&token=0cc2c841-546b-42f2-8829-02defea19ae9';
  return null;
};

function AppContent() {
  const { user } = useAuth();
  const userRole = user?.role || localStorage.getItem('userRole');
  const isAdminOrSales = userRole === 'admin' || userRole === 'sales_manager';
  
  return (
    <>
      <Navigation userRole={userRole || 'guest'} />
      <Container maxWidth="lg">
        <Suspense fallback={<div>טוען...</div>}>
          <Routes>
            <Route path="/" element={<ThankYouPage isWelcomePage={true} />} />
          <Route path="/login" element={<LoginScreen />} />
          <Route path="/form" element={<LeadCaptureForm />} />
          <Route
            path="/table"
            element={
              <ProtectedRoute allowedRoles={['sales_manager', 'admin']}>
                <LeadTable />
              </ProtectedRoute>
            }
          />
          <Route path="/thank-you" element={<ThankYouPage />} />
          <Route
            path="/welcome"
            element={<ThankYouPage isWelcomePage={true} />}
          />
          <Route
            path="/products"
            element={
              <ProtectedRoute allowedRoles={['admin', 'sales_manager']}>
                <ProductManagement />
              </ProtectedRoute>
            }
          />

          <Route
            path="/order"
            element={
              <ProtectedRoute
                allowedRoles={['client', 'sales_manager', 'admin']}
              >
                <OrderPlacementWizard />
              </ProtectedRoute>
            }
          />
          <Route path="/order-thank-you" element={<OrderThankYouPage />} />
          <Route
            path="/generate-pdf/:leadId/:orderId"
            element={<GeneratePDF />}
          />
          <Route
            path="/sales"
            element={
              <ProtectedRoute allowedRoles={['admin', 'sales_manager']}>
                <OrderManagement />
              </ProtectedRoute>
            }
          />
          <Route
            path="/data"
            element={
              <ProtectedRoute allowedRoles={['admin', 'sales_manager']}>
                <DataVisualizations />
              </ProtectedRoute>
            }
          />
          <Route path="/catalog" element={<CatalogRedirect />} />
          <Route path="/group-purchase" element={<GroupPurchaseForm />} />
          <Route
            path="/group-purchase-thank-you"
            element={<GroupPurchaseThankYou />}
          />
          <Route
            path="/group-purchase-admin"
            element={
              <ProtectedRoute allowedRoles={['admin', 'sales_manager']}>
                <GroupPurchaseAdmin />
              </ProtectedRoute>
            }
          />
          
          {/* Content management routes */}
          <Route
            path="/content-management"
            element={
              <ProtectedRoute allowedRoles={['admin']}>
                <ResourceManagement />
              </ProtectedRoute>
            }
          />
          
          {/* Public content pages */}
          <Route path="/knowledge" element={<KnowledgeGallery />} />
          <Route path="/pests" element={<PestsGallery />} />
          <Route path="/:type/:id" element={<ResourceDetail />} />
        </Routes>
      </Suspense>
    </Container>
    </>
  );
}

function App() {
  return (
    <HelmetProvider>
      <StyledEngineProvider injectFirst>
        <CacheProvider value={cacheRtl}>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <AuthProvider>
              <Router>
                <AppContent />
              </Router>
            </AuthProvider>
          </ThemeProvider>
        </CacheProvider>
      </StyledEngineProvider>
    </HelmetProvider>
  );
}

export default App;
