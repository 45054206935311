import React, { useState } from 'react';
import { 
  AppBar, 
  Toolbar, 
  Typography, 
  Button, 
  Box, 
  Menu, 
  MenuItem, 
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Divider,
  Avatar,
  useMediaQuery,
  useTheme,
  Tooltip,
  Container
} from '@mui/material';
import { 
  Menu as MenuIcon, 
  Dashboard as DashboardIcon,
  PeopleAlt as UsersIcon,
  Inventory as ProductsIcon,
  ShoppingCart,
  ShoppingBasket as OrderIcon,
  BarChart as DataIcon,
  Article as KnowledgeIcon,
  BugReport as PestIcon,
  EditNote as ContentIcon,
  Logout as LogoutIcon,
  Person as PersonIcon,
  Home as HomeIcon,
  Login as LoginIcon,
  AppRegistration as RegisterIcon,
  ShoppingBag as CatalogIcon,
  Groups as GroupPurchaseIcon
} from '@mui/icons-material';
import { Link as RouterLink, useLocation } from 'react-router-dom';

const Navigation = ({ userRole = 'guest' }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [mobileOpen, setMobileOpen] = useState(false);
  const [profileMenuAnchor, setProfileMenuAnchor] = useState(null);
  const location = useLocation();
  
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  
  const handleProfileMenuOpen = (event) => {
    setProfileMenuAnchor(event.currentTarget);
  };
  
  const handleProfileMenuClose = () => {
    setProfileMenuAnchor(null);
  };
  
  const navItems = [
    // Guest navigation items
    { 
      label: 'דף הבית', 
      path: '/',
      icon: <HomeIcon />,
      roles: ['guest', 'client', 'admin', 'sales_manager'],
    },
    { 
      label: 'ידע מקצועי', 
      path: '/knowledge',
      icon: <KnowledgeIcon />,
      roles: ['guest', 'client', 'admin', 'sales_manager'],
    },
    { 
      label: 'מגדיר מזיקים', 
      path: '/pests',
      icon: <PestIcon />,
      roles: ['guest', 'client', 'admin', 'sales_manager'],
    },
    { 
      label: 'קטלוג מוצרים', 
      path: '/catalog',
      icon: <CatalogIcon />,
      roles: ['guest', 'client', 'admin', 'sales_manager'],
    },
    { 
      label: 'רכישה קבוצתית', 
      path: '/group-purchase',
      icon: <GroupPurchaseIcon />,
      roles: ['guest', 'client', 'admin', 'sales_manager'],
    },
    { 
      label: 'הרשמה', 
      path: '/form',
      icon: <RegisterIcon />,
      roles: ['guest'],
    },
    { 
      label: 'התחברות', 
      path: '/login',
      icon: <LoginIcon />,
      roles: ['guest'],
    },
    
    // Client items
    { 
      label: 'הזמנה חדשה', 
      path: '/order',
      icon: <OrderIcon />,
      roles: ['client', 'admin', 'sales_manager'],
    },
    
    // Admin and Sales items
    { 
      label: 'טבלת לידים', 
      path: '/table',
      icon: <UsersIcon />,
      roles: ['admin', 'sales_manager'],
    },
    { 
      label: 'ניהול הזמנות', 
      path: '/sales',
      icon: <ShoppingCart />,
      roles: ['admin', 'sales_manager'],
    },
    { 
      label: 'נתונים', 
      path: '/data',
      icon: <DataIcon />,
      roles: ['admin', 'sales_manager'],
    },
    
    // Admin-only items
    { 
      label: 'ניהול מוצרים', 
      path: '/products',
      icon: <ProductsIcon />,
      roles: ['admin'],
    },
    { 
      label: 'ניהול תוכן', 
      path: '/content-management',
      icon: <ContentIcon />,
      roles: ['admin'],
    },
    { 
      label: 'ניהול רכישה קבוצתית', 
      path: '/group-purchase-admin',
      icon: <ShoppingCart />,
      roles: ['admin', 'sales_manager'],
    },
  ];
  
  // Filter items based on user role
  const filteredNavItems = navItems.filter(item => 
    item.roles.includes(userRole)
  );
  
  const getUserRoleLabel = (role) => {
    switch(role) {
      case 'admin':
        return 'מנהל מערכת';
      case 'sales_manager':
        return 'מנהל מכירות';
      case 'client':
        return 'לקוח';
      case 'guest':
      default:
        return 'אורח';
    }
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center', pt: 2, pb: 2 }}>
      <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
        <Avatar 
          sx={{ 
            width: 70, 
            height: 70, 
            bgcolor: theme.palette.primary.main,
            mb: 1
          }}
        >
          <Typography variant="h5">RPC</Typography>
        </Avatar>
      </Box>
      
      <Typography variant="subtitle1" fontWeight="bold" sx={{ mb: 1 }}>
        {getUserRoleLabel(userRole)}
      </Typography>
      
      <Divider sx={{ mb: 2 }} />
      
      <List>
        {filteredNavItems.map((item) => (
          <ListItem 
            key={item.path} 
            component={RouterLink} 
            to={item.path}
            sx={{ 
              color: 'inherit',
              textDecoration: 'none',
              py: 1,
              bgcolor: location.pathname === item.path ? 'rgba(171, 205, 55, 0.1)' : 'transparent',
              '&:hover': {
                bgcolor: 'rgba(171, 205, 55, 0.05)',
              },
            }}
          >
            <ListItemIcon sx={{ minWidth: 40 }}>
              {item.icon}
            </ListItemIcon>
            <ListItemText primary={item.label} />
          </ListItem>
        ))}
        
        {userRole !== 'guest' && (
          <>
            <Divider sx={{ my: 2 }} />
            
            <ListItem 
              component={RouterLink} 
              to="/logout"
              sx={{ 
                color: 'inherit',
                textDecoration: 'none',
                py: 1,
              }}
            >
              <ListItemIcon sx={{ minWidth: 40 }}>
                <LogoutIcon />
              </ListItemIcon>
              <ListItemText primary="התנתקות" />
            </ListItem>
          </>
        )}
      </List>
    </Box>
  );
  
  return (
    <>
      <AppBar 
        position="sticky" 
        color="default"
        elevation={2}
        sx={{
          bgcolor: 'white',
          borderBottom: '1px solid rgba(0, 0, 0, 0.05)',
          boxShadow: '0 2px 10px rgba(0, 0, 0, 0.05)',
        }}
      >
        <Container maxWidth="xl" sx={{ position: 'relative' }}>
          <Toolbar sx={{ 
            py: { xs: 0.5, md: 0.5 },
            minHeight: { xs: '48px', sm: '52px' },
            position: 'relative'
          }}>
            {/* Mobile menu button - on the right side for RTL layout */}
            {isMobile && (
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerToggle}
                sx={{ 
                  mr: 1,
                  backgroundColor: 'rgba(171, 205, 55, 0.1)',
                  transition: 'all 0.2s ease',
                  '&:hover': {
                    backgroundColor: 'rgba(171, 205, 55, 0.2)',
                    transform: 'scale(1.05)'
                  } 
                }}
              >
                <MenuIcon />
              </IconButton>
            )}
            
            {/* Empty spacer to take up space */}
            <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center' }}>
              {/* You can add something in center if needed in the future */}
            </Box>
            
            {/* Menu button on the right for desktop */}
            {!isMobile && (
              <Box>
                <Tooltip title="תפריט">
                  <IconButton 
                    onClick={handleProfileMenuOpen}
                    color="inherit"
                    sx={{ 
                      mr: 1,
                      backgroundColor: 'rgba(171, 205, 55, 0.1)',
                      transition: 'all 0.2s ease',
                      '&:hover': {
                        backgroundColor: 'rgba(171, 205, 55, 0.2)',
                        transform: 'scale(1.05)'
                      } 
                    }}
                  >
                    <MenuIcon />
                  </IconButton>
                </Tooltip>
                <Menu
                  anchorEl={profileMenuAnchor}
                  open={Boolean(profileMenuAnchor)}
                  onClose={handleProfileMenuClose}
                  sx={{ mt: 1 }}
                >
                  {filteredNavItems.map((item) => (
                    <MenuItem 
                      key={item.path}
                      component={RouterLink}
                      to={item.path}
                      onClick={handleProfileMenuClose}
                      sx={{ 
                        minWidth: 150,
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1,
                      }}
                    >
                      {item.icon}
                      {item.label}
                    </MenuItem>
                  ))}
                  {userRole !== 'guest' && (
                    <>
                      <Divider />
                      <MenuItem 
                        component={RouterLink}
                        to="/logout"
                        onClick={handleProfileMenuClose}
                        sx={{ 
                          display: 'flex',
                          alignItems: 'center',
                          gap: 1,
                        }}
                      >
                        <LogoutIcon />
                        התנתקות
                      </MenuItem>
                    </>
                  )}
                </Menu>
              </Box>
            )}
          </Toolbar>
        </Container>
      </AppBar>
      
      {/* Mobile navigation drawer */}
      <Drawer
        variant="temporary"
        anchor="right" // RTL layout - drawer from right
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile
        }}
        sx={{
          display: { xs: 'block', md: 'none' },
          '& .MuiDrawer-paper': { 
            boxSizing: 'border-box', 
            width: 270,
          },
        }}
      >
        {drawer}
      </Drawer>
    </>
  );
};

export default Navigation;
